import React from "react"
import LayoutNoStripe from "../../components/layoutNoStripe"
import HeroBanner from "../../components/HeroBanner"
import { HeroText } from "../../styledComponents/heroBanner"
import { ReactVideoPlayer } from "../../components/reactPlayer"

const HandToHeartVideo = ({ location }) => {
  return (
    <>
      <LayoutNoStripe location={location} isHomePage>
        <HeroBanner
          type="hand-to-heart"
        >
          <HeroText>
          <ReactVideoPlayer 
              videoURL="https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/hand-to-heart/h2h-caricature.mp4"
              title="Hand to heart video"
          />
          </HeroText>
        </HeroBanner>
      </LayoutNoStripe>
    </>
  )
}

export default HandToHeartVideo
